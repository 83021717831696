<template>
    <!-- Map card -->
    <div class="card bg-gradient-primary">
        <div class="card-header border-0">
            <h3 class="card-title"><i class="fas fa-map-marker-alt mr-1"></i> {{this.getSiteProps('general.admin-team') || 'Clubs'}} </h3>
            <!-- card tools -->
            <div class="card-tools">
                <!-- <button type="button"
                        class="btn btn-primary btn-sm daterange"
                        data-toggle="tooltip"
                        title="Date range">
                    <i class="far fa-calendar-alt"></i>
                </button> -->
                <button type="button"
                        class="btn btn-primary btn-sm"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
            <!-- /.card-tools -->
        </div>
        <div class="card-body">
            <div id="usa-map1" style="height: 250px; width: 100%;"></div>
        </div>
        <!-- /.card-body-->
    </div>
    <!-- /.card -->
</template>

<script>
import axios from 'axios';

import jsVectorMap from 'jsvectormap';

// US Map style
require('jsvectormap/dist/css/jsvectormap.css');

// US MAP
require('../admin/maps/usa-en.js');

let membersData = {};

export default {
   name: 'UsaMapNew',

   mounted() {
       console.log('membersData', membersData);
       if(Object.keys(membersData).length === 0){
            axios.get(process.env.VUE_APP_URL + 'api/dashboard/members/4')
            .then((response) => {
                membersData = response.data.membersData;
                this.renderMap();
                })
            .catch(error => this.errors = error.response.data);
            return false;
        }
        this.renderMap();
   },

   methods: {
       renderMap() {
           const map = new jsVectorMap({
               visualizeData: {
                    scale: ['#DDD6FE', '#4C1D95'],
                    values: membersData
                },
                selector: '#usa-map1',
                map: 'usa_en',
                zoomButtons: false,
                onRegionTooltipShow(tooltip, code) {
                    let quantity = membersData[code] ?? 0;
                    tooltip.css({ 
                        backgroundColor: '#EFF6FF',
                        color: '#6B7280',
                        padding: '8px 6px',
                        borderLeft: '6px solid #ccc',
                        borderColor: '#2563EB',
                        borderRadius: '3px',
                        fontWeight: 'bold'
                    }).text(tooltip.text() + ': ' + quantity);
                }
            });
       }
   }
}
</script>

<style scoped>
</style>
