<template>
    <div class="row">
        <div class="col-lg-3 col-6" v-if="admindashboard.individualCounts != undefined && admindashboard.individualCounts.visible">
            <!-- small box -->             
            <small-box bg="bg-info" :value="stats.individualCounts" :title="admindashboard.individualCounts.title" icon="ion-person" :link="admindashboard.individualCounts.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.familyCounts != undefined && admindashboard.familyCounts.visible">
            <!-- small box -->
            <small-box bg="bg-secondary" :value="stats.familyCounts"  :title="admindashboard.familyCounts.title" icon="ion-ios-people" :link="admindashboard.familyCounts.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.staffCounts != undefined && admindashboard.staffCounts.visible">
            <!-- small box -->
            <small-box bg="bg-secondary" :value="stats.staffCounts"  :title="admindashboard.staffCounts.title" icon="ion-ribbon-b" :link="admindashboard.staffCounts.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.clubsCounts != undefined && admindashboard.clubsCounts.visible">
            <!-- small box -->
            <small-box bg="bg-danger" :value="stats.clubsCounts" :title="admindashboard.clubsCounts.title" icon="ion-flag" :link="admindashboard.clubsCounts.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.coachCounts != undefined && admindashboard.coachCounts.visible">
            <!-- small box -->
            <small-box bg="bg-warning" :value="stats.coachCounts" :title="admindashboard.coachCounts.title" icon="ion-ribbon-b" :link="admindashboard.coachCounts.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.newOrders != undefined && admindashboard.newOrders.visible">
            <!-- small box -->
            <small-box bg="bg-danger" :value="stats.newOrders" :title="admindashboard.newOrders.title" icon="ion-pie-graph" :link="admindashboard.newOrders.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.freeAgent != undefined && admindashboard.freeAgent.visible">
          <!-- small box -->
          <small-box bg="bg-warning" :value="stats.freeAgentCounts" :title="admindashboard.freeAgent.title" icon="ion-android-person-add" :link="admindashboard.freeAgent.link"/>
        </div>
        <div class="col-lg-3 col-6" v-if="admindashboard.campskiCounts != undefined && admindashboard.campskiCounts.visible">
          <!-- small box -->
          <small-box bg="bg-warning" :value="stats.campskiCounts" :title="admindashboard.campskiCounts.title" icon="ion-ios-snowy" :link="admindashboard.campskiCounts.link"/>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SmallBox from './SmallBox';
let cachedData = {};

export default {
    name: 'statbox',
    components: { SmallBox },

    data() {
        return {
            stats: {
              individualCounts: '...',
              familyCounts: '...',
              clubsCounts: '...',
              campskiCounts: '...',
              newOrders: '...',
              countCounts: '...',
              staffCounts: '...',
              freeAgentCounts: '...',
              coachCounts: '...'
            },
            errors: {},
            admindashboard : {}
        }
    },

    mounted() {        
        const retry = setInterval(() => {
            this.admindashboard = this.getSiteProps('admindashboard.stats');              
            if(this.admindashboard != "") clearInterval(retry);
        }, 200);


        // Axios GET: from API
        if(Object.keys(cachedData).length === 0){
            axios.get(process.env.VUE_APP_URL + 'api/dashboard/stats')
                .then((response) => {
                    cachedData = response.data.data;
                    this.stats = cachedData;
                })
                .catch(error => this.errors = error.response.data);
                return false;
        }
        this.stats = cachedData;


    }
}
</script>

<style scoped>

</style>
