<template>
    <!-- Custom tabs (Charts with tabs)-->
    <div class="card">
        <div class="card-header bg-white">
            <h3 class="card-title">
            <i class="fas fa-chart-pie mr-1"></i>
            Total Registrations
            </h3>
            <div class="card-tools">
                <ul class="nav ml-auto">
                    <li class="nav-item">
                    <a class="nav-link active" href="#year-chart" data-toggle="tab" @click="enableMonthly">12-Month</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#week-chart" data-toggle="tab" @click="enableWeekly">7-Day</a>
                    </li>
                </ul>
            </div>
        </div><!-- /.card-header -->
        <div class="card-body">
            <div class="tab-content p-0">
                <!-- Chart - Registrations -->
                <div class="chart tab-pane active bg-white" id="year-chart" style="position: relative; height: 400px;">
                    <div style="position:absolute;left:45%;top:40%;opacity: .55" v-if="!loaded">
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </div>
                    <line-chart :chartdata="chartData" :options="chartOptions" v-if="loaded"/>                         
                </div>
                <div class="chart tab-pane bg-white" :class="{ active : weekly }" id="week-chart" style="position: relative; height: 400px;">
                    <div style="position:absolute;left:45%;top:40%;opacity: .55" v-if="!loaded">
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </div>
                    <line-chart :chartdata="chartData" :options="chartOptions" v-if="loaded && weekly"/>                 
                </div>  
            </div>
        </div><!-- /.card-body -->
    </div>
</template>

<script>
import LineChart from './Chart';
import axios from 'axios';

let monthlyApiData = {};
let weeklyApiData = {};

export default {
    name: 'LineChartContainer',
    components: { LineChart },  
    data() {
        return {
            loaded: false,
            weekly: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        //label: '2020 - 2021',
                        label: '',
                        fill: false,
                        borderColor: '#60A5FA',
                        backgroundColor: '#3B82F6',
                        data: []
                    },
                    {
                        //label: '2019 - 2020',
                        label: '',
                        fill: false,
                        borderColor: '#D1D5DB',
                        backgroundColor: '#9CA3AF',
                        data: []
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }],
                    // yAxes: [{
                    //     gridLines: {
                    //         display: false
                    //     }
                    // }]
                }
            }
        }
    },
    methods: {
        getChartData(timeSpan = '') {
            this.loaded = false;
            axios.get(process.env.VUE_APP_URL + 'api/dashboard/registrations' + timeSpan)
            .then((response) => {
                this.loaded = true;
                if(timeSpan !== ''){
                    weeklyApiData = response.data;
                    return;
                }
                monthlyApiData = response.data;
                this.enableMonthly();
                })
            .catch(error => this.errors = error.response.data); 
        },

        enableMonthly() {
            this.chartData.labels = monthlyApiData.chartData.labels
            this.chartData.datasets[0].data = monthlyApiData.chartData.datasets[0];
            this.chartData.datasets[1].data = monthlyApiData.chartData.datasets[1];
            this.chartData.datasets[0].label = monthlyApiData.chartData.ranges[0];
            this.chartData.datasets[1].label = monthlyApiData.chartData.ranges[1];
        },

        enableWeekly() {
            this.chartData.labels = weeklyApiData.chartData.labels
            this.chartData.datasets[0].data = weeklyApiData.chartData.datasets[0];
            this.chartData.datasets[1].data = weeklyApiData.chartData.datasets[1];
            this.chartData.datasets[0].label = weeklyApiData.chartData.ranges[0];
            this.chartData.datasets[1].label = weeklyApiData.chartData.ranges[1];
            this.weekly = true;
        }
    },
    mounted() {
        if(Object.keys(monthlyApiData).length === 0){
            this.getChartData();
            this.getChartData('/weekly');
            return;
        }
        this.loaded = true;
        this.enableMonthly();
    }
}
</script>

<style scoped>

</style>
