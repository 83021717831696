<template>
    <div class="card">
        <div class="card-body">
            <!-- Chart - Registrations -->
            <div class="chart bg-white" id="age-chart" style="position: relative; height: 240px">
                <div style="position:absolute;left:35%;top:35%; opacity: .55" v-if="!loaded">
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </div>
                <pie-chart :chartdata="chartData" :options="chartOptions" v-if="loaded"/>                         
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import PieChart from './PieChart';
let cachedData = {};

export default {
    name: 'PieChartContainer',
    props: {
        title: String,
        apiUrl: String,
    },
    components: { PieChart },  
    data() {
        return {
            loaded: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [],
                        data: []
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    labels: {
                        fontColor: '#6B7280'
                    },
                    position: 'right',
                    align: 'center',
                },
                title: {
                    display: true,
                    padding: 0,
                    lineHeight: 1,
                    position: 'top',
                    text: this.title,
                    fontSize: 14,
                    fontColor: '#6B7280'
                },
            },
        }
    },
    methods: {
        getChartData() {
            this.loaded = false;
            if(Object.keys(cachedData).length === 0){
                axios.get(process.env.VUE_APP_URL + 'api/dashboard/' + this.apiUrl)
                .then((response) => {
                    cachedData[this.apiUrl] = response.data.chartData;
                    this.chartData = cachedData[this.apiUrl];
                    this.loaded = true;
                    })
                .catch(error => this.errors = error.response.data);
                return false;
            }

            this.chartData = cachedData[this.apiUrl];
            this.loaded = true;

        },
    },
    mounted() {
        this.getChartData();
    }
}
</script>

<style scoped>

</style>
