<template>
    <div class="small-box" :class="bg">
            <div class="inner">
                <h3>{{ value }}<sup v-if="supActive" style="font-size: 20px">%</sup>
                </h3>

                <p>{{ title }}</p>
            </div>
            <div class="icon">
                <i class="ion" :class="icon"></i>
            </div>
            <router-link :to="link || '#'" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></router-link>
        </div>
</template>

<script>

export default {
    name: 'smallbox',
    props: {
        bg: String,
        value: String,
        title: String,
        icon: String,
        supActive: Boolean,
        link: String
    }
}
</script>

<style scoped>

</style>
